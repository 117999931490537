import React, { useEffect } from "react";
import Meta from "../../components/Meta";
import TrustedPartner from "../../components/dao/TrustedPartner";
import Hero_12 from "../../components/hero/hero_12";
import Benifits from "../../components/ico-landing/Benifits";
import CallToActions from "../../components/ico-landing/CallToActions";
import ProSaleBanner from "../../components/ico-landing/ProSaleBanner";
import RoadMap from "../../components/ico-landing/RoadMap";
import Faq from "../../components/ico-landing/faq";
import NamxPartners from "../../components/namx-partners/NamxPartners";

const Home_1 = () => {
  useEffect(() => {
    const header = document.querySelector("header");
    header.classList.add("bg-white/[.15]");
  }, []);

  return (
    <>
      <Meta title="Home" />
      <Hero_12 />
      <ProSaleBanner />
      <TrustedPartner />
      <Faq />
      <NamxPartners />
      <Benifits />
      <RoadMap />
      <CallToActions />
    </>
  );
};

export default Home_1;
