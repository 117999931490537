import Link from "next/link";
import ProSaleCounter from "./ProSaleCounter";

const ProSaleBanner = () => {
  return (
    <div className="container">
      <div className="rounded-2.5xl bg-light-base px-6 py-16 shadow-sm dark:bg-jacarta-700 md:px-16 lg:px-24">
        <div className="flex-wrap justify-between lg:flex">
          <div className="mb-14">
            <h2 className="mb-4 font-display text-3xl text-jacarta-700 dark:text-white">
              {/* ICO Pre-Sale is */}
              <span className="text-accent">Join Our Listing Competition!</span>
            </h2>
            <p className="mb-8 text-lg text-jacarta-500 dark:text-jacarta-300">
              Referral Bonus: 10%
            </p>
            <Link
              href="/competitions"
              className="inline-block rounded-full bg-accent py-2.5 px-8 text-center text-sm font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            >
              More Info
            </Link>
          </div>
          {/* End left text */}

          <div>
            <ProSaleCounter />
            {/* End pro sale countdown banner */}

            {/* start Progress */}
            <div className="mb-2 flex justify-between dark:text-jacarta-300">
              <span>
                NAMX Coinstore Referrals:{" "}
                <span className="text-green font-medium">102</span>
              </span>
              <span>1,000</span>
            </div>
            <div className="rounded bg-accent-lighter">
              <div className="h-4 rounded bg-accent w-[10.2%]" />
            </div>

            {/* End progress */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProSaleBanner;
