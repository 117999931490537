import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const RoadMap = () => {
  const [itemsTabs, setItemsTabs] = useState(1);

  const roadMapList = [
    {
      id: 1,
      tabMenu: "2025 Q1",
    },
    {
      id: 2,
      tabMenu: "2025 Q1",
    },
    {
      id: 3,
      tabMenu: "2025 Q2",
    },
    {
      id: 4,
      tabMenu: "2025 Q2",
    },
    {
      id: 5,
      tabMenu: "2024 Q1",
    },
    {
      id: 6,
      tabMenu: "2024 Q3/4",
    },
    {
      id: 7,
      tabMenu: "2023 Q3",
    },
    {
      id: 8,
      tabMenu: "2023 Q3",
    },
    {
      id: 9,
      tabMenu: "2023 Q3",
    },
    {
      id: 10,
      tabMenu: "2023 Q2",
    },
    {
      id: 11,
      tabMenu: "2022 Q3",
    },
    {
      id: 12,
      tabMenu: "2022 Q2",
    },
    {
      id: 13,
      tabMenu: "2022 Q1",
    },
    {
      id: 14,
      tabMenu: "2022 Q1",
    },
    {
      id: 15,
      tabMenu: "2021 Q3",
    },
    {
      id: 16,
      tabMenu: "2021 Q1",
    },
    {
      id: 17,
      tabMenu: "2020 Q3",
    },
    {
      id: 18,
      tabMenu: "2020 Q2",
    },
    {
      id: 19,
      tabMenu: "2020 Q1",
    },
  ];

  const roadMapContent = [
    {
      id: 1,
      text: "Implementation of NAMX as payment method",
    },
    {
      id: 2,
      text: "Regulatory clearance for remittance licensing for cross-border payment",
    },
    {
      id: 3,
      text: "Launch of Dapps and Wallet infrastructure for payments and transfer of NAMX Balances",
    },
    {
      id: 4,
      text: "IoT devices for the measurement of key public resource metrics in Smart City application",
    },
    {
      id: 5,
      text: "Introduction of Defi opportunities to increase Utility and Adoption",
    },
    {
      id: 6,
      text: "1st Phase of water network infrastructure is operational",
    },
    {
      id: 7,
      text: "Expand NAMX Team to further align with project objectives",
    },
    {
      id: 8,
      text: "List on Coinstore exchange",
    },
    {
      id: 9,
      text: "TOKEN2049 Singapore | Networking and Partnership Development",
    },
    {
      id: 10,
      text: "List on Whitebit Exchange – over $60,000usd airdrop for early adopters",
    },
    {
      id: 11,
      text: "Add Vesting periods for Owners and Teams, add Liquidity Pool pairs",
    },
    {
      id: 12,
      text: "NAMX SNS and communities launched",
    },
    {
      id: 13,
      text: "BBW and NAMX partnership is announced for Smart City and Payment Solutions",
    },
    {
      id: 14,
      text: "NAMX token contract creation and deployment",
    },
    {
      id: 15,
      text: "Regulatory process, establishment of companies, purchasing of land",
    },
    {
      id: 16,
      text: "Opening of investment stages for the infrastructure of the water pipeline and tap water network for Myeik city",
    },
    {
      id: 17,
      text: "BBW announced as bid winner. Concession signing ceremony in Myeik, Myanmar",
    },
    {
      id: 18,
      text: "Bid Submission for concession consideration",
    },
    {
      id: 19,
      text: "Completion of feasibility and infrastructure study",
    },
  ];

  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat py-24 after:absolute after:inset-0 after:bg-jacarta-900/60"
      style={{
        backgroundImage: 'url("/images/ico-landing/ico_landing_roadmap.jpg")',
      }}
    >
      <div className="container relative z-10">
        <h2 className="mb-6 font-display text-3xl text-white">Roadmap</h2>
        <p className="mb-12 max-w-xl text-lg text-jacarta-300">
          This timeline details our funding and development goals. Connect our
          AI to your exchange account and invest crypto automatically.
        </p>

        <Tabs>
          <div className="flex">
            <TabList className="nav nav-tabs w-1/3 space-y-9 self-start border-l-2 border-jacarta-200 py-2 pl-2 md:pl-8">
              {roadMapList.map((item) => (
                <Tab
                  className="nav-item relative"
                  key={item.id}
                  onClick={() => setItemsTabs(item.id)}
                >
                  <button
                    className={
                      itemsTabs === item.id
                        ? "active nav-link nav-link--style-3 relative flex items-center whitespace-nowrap text-jacarta-300 hover:text-white"
                        : "nav-link nav-link--style-3 relative flex items-center whitespace-nowrap text-jacarta-300 hover:text-white"
                    }
                  >
                    <span className="px-2 font-display text-lg font-medium md:text-2xl">
                      {item.tabMenu}
                    </span>
                  </button>
                </Tab>
              ))}
            </TabList>
            {/* End Tablist  */}

            <div className="tab-content w-full h-screen flex justify-center items-center text-center text-2xl font-medium">
              {roadMapContent.map((item) => (
                <TabPanel key={item.id}>
                  <p>{item.text}</p>
                </TabPanel>
              ))}
            </div>
            {/* End tab-content */}
          </div>
        </Tabs>
      </div>
    </section>
  );
};

export default RoadMap;
