/* eslint-disable react/no-unescaped-entities */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import Link from "next/link";
import { useState } from "react";
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-4 w-4 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const itemContent = [
  {
    id: 1,
    title: "Medium-Term",
    descriptions: `In the medium term, NAMX is focusing on increasing the utility of the token outside of our long-term objectives. This includes defi products and services with Staking, Farming and Liquidity Pool investments for Decentralized Exchanges. As we setup our nodes to participate in the validation of the Ethereum Network, we will provide further updates to keep you informed on how you can participate and earn interest on your NAMX tokens.`,
  },
  {
    id: 2,
    title: "Long-Term",
    descriptions: `Our Long-Term objectives consist of much bigger plans – from our Smart City implementation in Myeik, to using the NAMX Network as a Remittance service for international transfers (similar to PayPal, only faster and cheaper!). You can get more information by clicking on the “About” tab on the menu, or by downloading the Whitepaper.`,
  },
  {
    id: 3,
    title: "Dive Deeper",
    button: [
      {
        id: 1,
        title: "White Paper",
        handler: () => {
          const pdfUrl = "/pdf/The-Namx-Whitepaper.pdf";
          const link = document.createElement("a");
          link.href = pdfUrl;
          link.download = "The Namx Whitepaper.pdf";
          link.click();
          link.remove();
        },
      },
      {
        id: 2,
        title: "About",
        path: "/about",
      },
    ],
  },
];

const FaqAccordion = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      {itemContent.map((item) => (
        <Accordion
          className="accordion-item mb-5 overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600 h-auto"
          key={item.id}
          open={open === item.id}
          icon={<Icon id={item.id} open={open} />}
        >
          <AccordionHeader
            className="accordion-button collapsed relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-sm text-jacarta-700 dark:bg-jacarta-700 dark:text-white"
            onClick={() => handleOpen(item.id)}
          >
            {item.title}
          </AccordionHeader>
          <div className="hidden-style">
            <AccordionBody className="accordion-body border-t border-jacarta-100 bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-700">
              {item.descriptions ? (
                item.descriptions
              ) : (
                <div className="flex text-center gap-5">
                  {item.button.map((btn) =>
                    btn.handler ? (
                      <button
                        key={btn.id}
                        onClick={btn.handler}
                        //  button design will look like the accordion header
                        className="inline-block w-full  mb-4 px-4 py-3 text-sm font-semibold text-white bg-accent rounded-lg shadow-accent-volume hover:bg-accent-dark"
                      >
                        {btn.title}
                      </button>
                    ) : (
                      <Link
                        key={btn.id}
                        href={btn.path || ""}
                        className="inline-block w-full mb-4 px-4 py-3 text-sm font-semibold text-white bg-accent rounded-lg shadow-accent-volume hover:bg-accent-dark"
                      >
                        {btn.title}
                      </Link>
                    )
                  )}
                </div>
              )}
            </AccordionBody>
          </div>
        </Accordion>
      ))}
    </>
  );
};

export default FaqAccordion;
