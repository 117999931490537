import Image from "next/image";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const Benifits = () => {
  const singleBenifits = [
    {
      id: 1,
      icon: "w1",
      title: "Smart Utility",
      text: `Pay Utility bills using NAMX! It’s not only more convenient, it generates usage and payment data. Combining the data with feedback from IoT devices throughout the city allows local governments to be more efficient with public resources.`,
    },
    {
      id: 2,
      icon: "w2",
      title: "Cross-border Transactions",
      text: `A large percentage of South East Asia remains unbanked. With NAMX as the payment token, people working in neighboring countries can send money back home without having to use expensive money transfer services.`,
    },
    {
      id: 3,
      icon: "w3",
      title: "Smart Meters",
      text: `Smart Meters allow the public to keep track of their water and electricity consumption. Convenient payment is done via a QR code. These meters also keep the public informed on any potential outages in the system, allowing you to plan ahead.`,
    },
    {
      id: 4,
      icon: "w4",
      title: "Defi",
      text: `Connecting the unbanked population to a publicly verifiable payment network has the added benefit of opening greater investment and asset management solutions to them via Decentralized Finance opportunities.`,
    },
    {
      id: 5,
      icon: "w5",
      title: "Blockchain Gateway",
      text: `Without access to traditional financial services, citizens are forced to use expensive services transfer funds. Over The Counter facilities -already in place for bill payments – double up as a fiat gateway to access the world of blockchain products and services.`,
    },
  ];
  return (
    <>
      <section className="py-24 dark:bg-jacarta-900 benifit-section">
        <div className="container">
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={30}
            slidesPerView="auto"
            loop={true}
            breakpoints={{
              240: {
                slidesPerView: 1,
              },
              565: {
                slidesPerView: 2,
              },
              995: {
                slidesPerView: 3,
              },
            }}
            className="!pt-10"
          >
            {singleBenifits.map((item) => {
              const { id, icon, title, text } = item;
              return (
                <SwiperSlide className="text-white overflow-visible" key={id}>
                  <div
                    className="mb-12 rounded-2.5xl border border-jacarta-100 bg-white p-8 pt-0 text-center transition-shadow hover:shadow-xl dark:border-jacarta-600 dark:bg-jacarta-700"
                    key={id}
                  >
                    <div className="mb-9 -mt-8 inline-flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-full border border-jacarta-100 bg-white dark:border-jacarta-600 dark:bg-jacarta-700">
                      <Image
                        src={`/images/crypto-app/${icon}.svg`}
                        alt="icon"
                        width={48}
                        height={48}
                        className="h-12 w-12"
                      />
                    </div>

                    <h3 className=" mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                      {title}
                    </h3>
                    <p className="text-justify dark:text-jacarta-300 text-jacarta-700">
                      {text}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Benifits;
