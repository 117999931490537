const namx_partners_data = [
  {
    id: 1,
    name: "King Mongkut's Institute of Technology",
    website: "https://www.kmitl.ac.th/",
    logo: "/images/namx-partners/kmitl-logo.png",
    images: [
      "/images/namx-partners/kmitl1.jpg",
      "/images/namx-partners/kmitl2.jpg",
      "/images/namx-partners/kmitl3.jpg",
      "/images/namx-partners/kmitl4.jpg",
    ],
    description:
      "The King Mongkut's Institute of Technology Ladkrabang (KMITL) is ranked 3rd in Thailand by the World University Rankings overall and is ranked 1st as it relates to Research and Industry Income. The university is well known for its research and publications in technological development and also offers courses to students wanting to learn about Smart City development, policies and best practices. By leveraging this resource, Namx and BBWI can ensure that the best minds are involved in integrating Smart City technologies in Myeik. As Namx and BBWI intend to use IoT devices and blockchain technology in the city, the importance of having a partner like KMITL cannot be overlooked.",
  },
  {
    id: 2,
    name: "Bright Blue Group",
    website: "https://www.bbw.in.th/",
    logo: "/images/namx-partners/bbw-logo.png",
    ytVideo: "https://www.youtube.com/embed/Tph-3qpJS1A?si=9AL5peWLkY_624vf",
    images: [
      "/images/namx-partners/bbw1.jpg",
      "/images/namx-partners/bbw2.jpg",
      "/images/namx-partners/bbw3.jpg",
      "/images/namx-partners/bbw4.jpg",
    ],
    description:
      "Founded more than 20 years ago, the Bright Blue Group has relied on its knowledge and experience to help improve water standards and delivery efficiencies. For years, governments have entrusted the Bright Blue Group to implement and maintain equipment that is vital to the public and to the economy. Currently, the Bright Blue Group operates facilities in the Samut Sakorn and Pathumthani districts of Thailand, providing clean drinking water to industrial and residential consumers. The Bright Blue Group is constantly adding new projects to its portfolio as it expands it's reach throughout Thailand and into neighboring countries in Southeast Asia.",
  },
];

export default namx_partners_data;
