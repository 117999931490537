import Image from "next/image";

const hero_12 = () => {
  const handleDownload = () => {
    // Construct the URL to the PDF file in the public directory.
    const pdfUrl = "/pdf/The-Namx-Whitepaper.pdf";

    // Create an link element to trigger the download.
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "The Namx Whitepaper.pdf";
    link.click();

    // Remove the link element after the download starts.
    link.remove();
  };

  return (
    <section className="relative h-screen pt-10 lg:pt-20">
      <picture className="pointer-events-none absolute inset-0 -z-10">
        <Image
          width={1519}
          height={722}
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full object-cover"
        />
      </picture>

      <picture className="pointer-events-none absolute inset-y-0 right-6 -z-10 hidden items-center justify-end md:flex xl:right-[10%]">
        <Image
          width={662}
          height={513}
          src="/images/ico-landing/ico_landing_hero.png"
          alt="hero"
          className="w-3/4 xxl:w-auto object-cover"
        />
      </picture>

      <div className="ml-auto mr-auto h-full max-w-[91rem] px-4 lg:px-8">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start lg:py-20">
            {/* <p className="mb-5 inline-block bg-gradient-to-r from-[#F43771] to-[#FF9567] bg-clip-text text-lg text-transparent">
              Decentralized Application
            </p> */}
            <h1 className="mb-8 text-center font-display text-5xl text-white md:text-left lg:text-6xl lg:pr-8">
              Smart Utilities for Smart Cities: The NAMX Network{" "}
            </h1>
            <div className="flex space-x-4">
              <button
                onClick={handleDownload}
                className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all duration-500 hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
              >
                White Paper
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero_12;
