import Image from "next/image";

const Partner1 = () => {
  const partnerItem = [
    "cls",
    "whitebit",
    "coinstore",
    "bbw",
    "kmitl",
    "cls",
    "whitebit",
    "coinstore",
    "bbw",
    "kmitl",
  ];
  return (
    <>
      {partnerItem.map((item, i) => (
        <div
          className="flex flex-shrink-0 items-center justify-center rounded-2.5xl border border-jacarta-100 bg-white p-6"
          key={i}
        >
          <Image
            width={252}
            height={92}
            className="object-contain w-[252px] h-[92px]"
            src={`/images/namx-network/${item}.png`}
            alt="partner item"
          />
        </div>
      ))}
    </>
  );
};

export default Partner1;
