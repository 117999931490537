import { useRouter } from "next/router";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Autoplay, EffectFade, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

import Image from "next/image";
import namx_partners_data from "../../data/namx-partners-data";

const NamxPartners = () => {
  const router = useRouter();

  return (
    <section>
      <section>
        <div class="relative flex">
          <div class="min-h-screen lg:w-1/3"></div>
          <div class="hidden w-3/4 min-h-screen bg-gray-100 dark:bg-gray-800 lg:block"></div>

          <div class="container flex flex-col justify-center w-full min-h-screen px-6 py-10 mx-auto lg:absolute lg:inset-x-0">
            <div className="lg:flex justify-between">
              <div>
                <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">
                  Our Partners
                </h1>
                <p className="text-lg my-3">
                  Building a Better Future Together
                </p>
              </div>
              <div class="flex items-center  lg:justify-start">
                <button
                  title="left arrow"
                  class="prev p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>

                <button
                  title="right arrow"
                  class="next p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 mx-6 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <Swiper
                navigation={{
                  prevEl: ".prev",
                  nextEl: ".next",
                  clickable: true,
                }}
                effect={"fade"}
                fadeEffect={{ crossFade: true }}
                modules={[Navigation, EffectFade]}
                spaceBetween={50}
                slidesPerView={1}
                role="region"
                aria-label="Carousel with partner images"
              >
                {namx_partners_data?.map((partner, partnerIndex) => (
                  <SwiperSlide key={partner.id}>
                    <div class="mt-5 lg:flex lg:items-center">
                      <>
                        <div class="relative lg:w-1/2">
                          <Swiper
                            navigation={{
                              prevEl: `.partner-image-prev-${partnerIndex}`,
                              nextEl: `.partner-image-next-${partnerIndex}`,
                              clickable: true,
                            }}
                            effect={"fade"}
                            fadeEffect={{ crossFade: true }}
                            modules={[Navigation, EffectFade, Autoplay]}
                            autoplay={{ delay: 2000 }}
                            spaceBetween={50}
                            slidesPerView={1}
                          >
                            {partner.images.map((image, index) => (
                              <SwiperSlide key={index} tabIndex="0">
                                <div className="w-full h-[25rem] rounded-lg overflow-hidden">
                                  <PhotoProvider>
                                    <PhotoView src={image}>
                                      <Image
                                        width={500}
                                        height={500}
                                        className="w-full h-full object-cover object-center"
                                        src={image}
                                        alt="partner-image"
                                      />
                                    </PhotoView>
                                  </PhotoProvider>
                                  {/* <iframe
                                    width="500"
                                    height="500"
                                    src={partner.ytVideo}
                                    title="YouTube video player"
                                    className="w-full h-full object-cover object-center"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                  ></iframe> */}
                                </div>
                              </SwiperSlide>
                            ))}
                            {/* Add the video as an additional slide */}
                            {partner.ytVideo && (
                              <SwiperSlide tabIndex="1">
                                <div className="w-full h-[25rem] rounded-lg overflow-hidden">
                                  <iframe
                                    width="500"
                                    height="500"
                                    src={partner.ytVideo}
                                    title={`YouTube video player ${partner.name}`}
                                    className="w-full h-full object-cover object-center"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              </SwiperSlide>
                            )}
                          </Swiper>
                          {/* You can customize these buttons as per your requirement */}
                          {/* <div class={`partner-image-prev-${partnerIndex}`}>
                            Prev
                          </div>
                          <div class={`partner-image-next-${partnerIndex}`}>
                            Next
                          </div> */}
                        </div>
                        <div class="mt-8 lg:px-10 lg:mt-0 space-y-6">
                          <div className="flex justify-between items-center">
                            <h1 class="text-2xl font-semibold text-gray-800 dark:text-white">
                              {partner.name}
                            </h1>
                            <span>
                              <PhotoProvider>
                                <PhotoView src={partner.logo}>
                                  <Image
                                    src={partner.logo}
                                    alt="partner-logo"
                                    width={65}
                                    height={65}
                                    className="rounded"
                                  />
                                </PhotoView>
                              </PhotoProvider>
                            </span>
                          </div>

                          <p class="max-w-xl text-justify">
                            {partner.description}
                          </p>
                          <button
                            onClick={() => router.push(partner.website)}
                            className="z-50 cursor-pointer rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all duration-500 hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                          >
                            Visit Website
                          </button>
                        </div>
                      </>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default NamxPartners;
